import { HandData } from "./CardData";
import CardsRow from "./CardsRow";

interface PlayFieldProps {
    hands: HandData[];
}

export default function PlayField(props: PlayFieldProps) {
    return <div className="mob-width flex grow">
        <div className="flex flex-col m-auto w-full">
            {props.hands.map(function (hand, index) {
                return <CardsRow hand={hand} key={index} />;
            })}
        </div>
    </div>;
}