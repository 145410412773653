import { ChartBarIcon, CogIcon, QuestionMarkCircleIcon, RefreshIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { CardData, CardNumericValue, CardSuit, CardValue, cloneCard, emptyCard, eqCards, HandData, handFromStr, isEmptyCard } from './CardData';
import GameHistoryItem from './GameHistoryItem';
import HandInfo from './HandInfo';
import InitInfo from './InitInfo';
import { Keyboard } from './Keyboard';
import PlayField from './PlayField';
import Popup from './Popup';
import Quickstart from './Quickstart';
import Rules from './Rules';
import Settings from './Settings';
import Share from './Share';
import Statistics from './Statistics';

interface ServerResponse {
  today: string,
  hand: string[],
  info: HandInfo
};

export const NUM_GUESSES = 7;
export const LS_PFIX = 'pnr_data';

const pnrUrl = 'https://gvf2x4l1jl.execute-api.eu-central-1.amazonaws.com/default/pnrPoker';

function App() {
  const [handIndex, setHandIndex] = useState(0);
  const [cardIndex, setCardIndex] = useState(0);

  const [showQuickstart, setQuickstart] = useState(false);
  const [showRules, setShowRules] = useState(false);
  const [showStats, setShowStats] = useState(false);
  const [showOpts, setShowOpts] = useState(false);
  const [showDuplicateWarning, setShowDuplicateWarning] = useState(false);

  const [isWin, setWin] = useState(false);
  const [isLose, setLose] = useState(false);
  const [showWin, setShowWin] = useState(false);
  const [showLose, setShowLose] = useState(false);

  const [serverResponse, setServerResponse] = useState<ServerResponse>();

  const [answer, setAnswer] = useState<CardData[]>();

  const initHands: HandData[] = [];

  for (let n = 0; n < NUM_GUESSES; n++)
    initHands.push({ guess: [], result: [] });

  const [hands, setHands] = useState<HandData[]>(initHands);

  const [history, setHistory] = useState<GameHistoryItem[]>([]);

  function updateHistory() {
    setHistory(history);
    localStorage.setItem(LS_PFIX + '_history', JSON.stringify(history));
  }

  const [darkMode, setDarkMode] = useState(false);
  const [hardMode, setHardMode] = useState(false);
  const [expertMode, setExpertMode] = useState(false);

  function levelName(): string {
    return hardMode ? 'Hard' : expertMode ? 'Expert' : 'Standard';
  }

  function updateHardMode(value: boolean) {
    setHardMode(value);
    if (value && expertMode) {
      setExpertMode(false);
      localStorage.setItem(LS_PFIX + '_expertMode', JSON.stringify(false));
    }

    localStorage.setItem(LS_PFIX + '_hardMode', JSON.stringify(value));
  }

  function updateExpertMode(value: boolean) {
    setExpertMode(value);

    if (value && hardMode) {
      setHardMode(false);
      localStorage.setItem(LS_PFIX + '_hardMode', JSON.stringify(false));
    }

    localStorage.setItem(LS_PFIX + '_expertMode', JSON.stringify(value));
  }

  useEffect(() => {
    const ls_darkMode = localStorage.getItem(LS_PFIX + '_darkmode');
    const ls_hardMode = localStorage.getItem(LS_PFIX + '_hardMode');
    const ls_expertMode = localStorage.getItem(LS_PFIX + '_expertMode');

    const n_darkMode = ls_darkMode !== null ? JSON.parse(ls_darkMode) === true : true;

    updateDarkMode(n_darkMode);

    updateHardMode(ls_hardMode !== null ? JSON.parse(ls_hardMode) === true : false);
    updateExpertMode(ls_expertMode !== null ? JSON.parse(ls_expertMode) === true : false);

    const ls_history = localStorage.getItem(LS_PFIX + '_history');

    if (ls_history !== null)
      setHistory(JSON.parse(ls_history));

    const ls_rulesShown = localStorage.getItem(LS_PFIX + '_rules_shown');

    if (ls_rulesShown === null) {
      setQuickstart(true);
      localStorage.setItem(LS_PFIX + '_rules_shown', 'true');
    }
  }, []);

  useEffect(() => {
    fetch(pnrUrl).then(r => r.json()).then((res) => {
      const newInfo: ServerResponse = res;
      const newAnswer = handFromStr(newInfo.hand.join(','));
      setAnswer(newAnswer);
      setServerResponse(newInfo);

      const ls_currentDate = localStorage.getItem(LS_PFIX + '_currentDate');

      if (ls_currentDate === newInfo.today) {
        const ls_currentHands = localStorage.getItem(LS_PFIX + '_hands');

        if (ls_currentHands != null) {
          const ls_handIndex = localStorage.getItem(LS_PFIX + '_handIndex');
          const ls_cardIndex = localStorage.getItem(LS_PFIX + '_cardIndex');

          if (ls_handIndex !== null && ls_cardIndex !== null) {
            const hands: HandData[] = JSON.parse(ls_currentHands);
            setHands(hands);
            setHandIndex(JSON.parse(ls_handIndex));
            setCardIndex(JSON.parse(ls_cardIndex));
          }

          const ls_win = localStorage.getItem(LS_PFIX + '_win');
          const ls_lose = localStorage.getItem(LS_PFIX + '_lose');

          if (ls_win !== null && JSON.parse(ls_win) === true) {
            setWin(true);

            setTimeout(() => {
              setShowWin(true);
            }, 2000);
          } else if (ls_lose !== null && JSON.parse(ls_lose) === true) {
            setLose(true);
            setShowLose(true);
          }
        }
      } else {
        localStorage.setItem(LS_PFIX + '_currentDate', newInfo.today);
        localStorage.removeItem(LS_PFIX + '_hands');
        localStorage.removeItem(LS_PFIX + '_handIndex');
        localStorage.removeItem(LS_PFIX + '_cardIndex');
        localStorage.removeItem(LS_PFIX + '_win');
        localStorage.removeItem(LS_PFIX + '_lose');
      }
    });
  }, []);

  const handSum = hands[handIndex < NUM_GUESSES ? handIndex : NUM_GUESSES - 1].guess.reduce((prev, current) => {
    return prev + (current.value !== CardValue.NONE ? CardNumericValue[current.value] : 0);
  }, 0);

  function duplicateWarning() {
    setShowDuplicateWarning(true);

    setTimeout(() => {
      setShowDuplicateWarning(false);
    }, 3000);
  }

  /**
    1. correct value, correct suit, correct position
    2. correct value, correct suit, wrong position
    3. correct value, wrong suit, correct position
    4. wrong value, correct suit, correct position
    5. correct value, wrong suit, wrong position
    6. wrong value, correct suit, wrong position
    7. wrong value, wrong suit, wrong position
  */
  function testGuess(guess: CardData[]): CardData[] {
    if (answer) {
      const hidden: CardData[] = answer.map(c => cloneCard(c));
      const result: CardData[] = [emptyCard(), emptyCard(), emptyCard(), emptyCard(), emptyCard()];

      // 1. correct value, correct suit, correct position
      for (let pos = 0; pos < guess.length; pos++) {
        if (eqCards(guess[pos], hidden[pos])) {
          result[pos] = cloneCard(guess[pos]);
          result[pos].success = true;
          result[pos].inPos = true;
          hidden[pos] = emptyCard();
        }
      }

      // 2. correct value, correct suit, wrong position
      for (let pos = 0; pos < guess.length; pos++) {
        if (isEmptyCard(result[pos])) {
          for (let hpos = 0; hpos < hidden.length; hpos++) {
            if (hpos !== pos) {
              if (eqCards(guess[pos], hidden[hpos])) {
                result[pos] = cloneCard(guess[pos]);
                result[pos].inPos = false;
                hidden[hpos] = emptyCard();
                break;
              }
            }
          }
        }
      }

      // 3. correct value, wrong suit, correct position
      for (let pos = 0; pos < guess.length; pos++) {
        if (isEmptyCard(result[pos])) {
          if (hidden[pos].value === guess[pos].value) {
            result[pos].value = guess[pos].value;
            result[pos].inPos = true;
            hidden[pos].value = CardValue.NONE;
          }
        }
      }

      // 4. wrong value, correct suit, correct position
      for (let pos = 0; pos < guess.length; pos++) {
        if (isEmptyCard(result[pos])) {
          if (hidden[pos].suit === guess[pos].suit) {
            result[pos].suit = guess[pos].suit;
            result[pos].inPos = true;
            hidden[pos].suit = CardSuit.NONE;
          }
        }
      }

      // 5. correct value, wrong suit, wrong position
      for (let pos = 0; pos < guess.length; pos++) {
        if (isEmptyCard(result[pos])) {
          for (let hpos = 0; hpos < hidden.length; hpos++) {
            if (hpos !== pos) {
              if (hidden[hpos].value === guess[pos].value) {
                result[pos].value = guess[pos].value;
                result[pos].inPos = false;
                hidden[hpos].value = CardValue.NONE;
                break;
              }
            }
          }
        }
      }

      // 6. wrong value, correct suit, wrong position
      for (let pos = 0; pos < guess.length; pos++) {
        if (isEmptyCard(result[pos])) {
          for (let hpos = 0; hpos < hidden.length; hpos++) {
            if (hpos !== pos) {
              if (hidden[hpos].suit === guess[pos].suit) {
                result[pos].suit = guess[pos].suit;
                result[pos].inPos = false;
                hidden[hpos].suit = CardSuit.NONE;
                break;
              }
            }
          }
        }
      }

      return result;
    } else return [];
  }

  function backspaceCard() {
    hands[handIndex].guess[cardIndex - 1] = { suit: CardSuit.NONE, value: CardValue.NONE };
    setCardIndex(cardIndex - 1);
  }

  function enterCard(suit: CardSuit, value: CardValue) {
    if (!isWin && !isLose && !showDuplicateWarning) {
      for (const card of hands[handIndex].guess) {
        if (eqCards(card, { suit, value })) {
          duplicateWarning();
          return;
        }
      }

      hands[handIndex].guess[cardIndex] = { suit, value };
      setCardIndex(cardIndex + 1);
      setHands([...hands]);
    }
  }

  function handDone() {
    if (!isWin) {
      const result = testGuess(hands[handIndex].guess);
      hands[handIndex].result = result;

      let hasWin = true;

      for (let n = 0; n < 5; n++) {
        if (!(result[n].success)) {
          hasWin = false;
          break;
        }
      }

      if (hasWin) {
        setWin(true);

        if (serverResponse) {
          history.push({
            date: serverResponse.today,
            guessNum: handIndex + 1,
            win: true
          });
          updateHistory();
        }

        localStorage.setItem(LS_PFIX + '_win', JSON.stringify(true));

        setTimeout(() => {
          setShowWin(true);
        }, 3000);
      }

      if (!hasWin && handIndex + 1 === NUM_GUESSES) {
        setLose(true);

        localStorage.setItem(LS_PFIX + '_lose', JSON.stringify(true));

        setTimeout(() => {
          setShowLose(true);
        }, 1000);

        if (serverResponse) {
          history.push({
            date: serverResponse.today,
            guessNum: NUM_GUESSES,
            win: false
          });
          updateHistory();
        }
      }

      setHands(hands);
      setHandIndex(handIndex + 1);
      setCardIndex(0);

      localStorage.setItem(LS_PFIX + '_hands', JSON.stringify(hands));
      localStorage.setItem(LS_PFIX + '_handIndex', JSON.stringify(handIndex + 1));
      localStorage.setItem(LS_PFIX + '_cardIndex', JSON.stringify(0));
    }
  }

  function updateDarkMode(val: boolean) {
    setDarkMode(val);
    localStorage.setItem(LS_PFIX + '_darkmode', JSON.stringify(val));

    if (val) {
      document.body.classList.add('dark');
      document.body.classList.add('bg-poker');
      document.body.classList.remove('bg-black');
    } else {
      document.body.classList.remove('dark');
      document.body.classList.remove('bg-poker');
      document.body.classList.add('bg-black');
    }
  }

  return <div>{(serverResponse ? <div className={'flex flex-col w-full h-full absolute  dark:bg-poker-900'}>

    <Popup title='QUICKSTART' show={showQuickstart} onClose={() => setQuickstart(false)}>
      <Quickstart />
    </Popup>

    <Popup title='PnrPOKER RULES AND GAMEPLAY' show={showRules} onClose={() => setShowRules(false)} >
      <Rules />
    </Popup>

    <Popup title='Statistics' show={showStats} onClose={() => setShowStats(false)}>
      <Statistics history={history} />
    </Popup>

    <Popup title='Settings' show={showOpts} onClose={() => setShowOpts(false)}>
      <Settings
        darkMode={darkMode}
        hardMode={hardMode}
        expertMode={expertMode}
        changeDarkMode={(val) => updateDarkMode(val)}
        changeHardMode={(val) => updateHardMode(val)}
        changeExpertMode={(val) => updateExpertMode(val)} />
    </Popup>

    <Popup title='You Win!' show={isWin && showWin} onClose={() => setShowWin(false)}>
      Congratulations!

      <Statistics history={history} />

      <Share guessNum={handIndex} hands={hands} level={levelName()} />
    </Popup>

    <Popup title='You Lose' show={!isWin && isLose && showLose} onClose={() => setShowLose(false)}>
      Sorry, better luck next time!

      <Statistics history={history} />
    </Popup>

    <nav className='bg-black flex-none'>
      <div className='flex items-center mob-width text-white p-1 md:text-lg md:p-4 m-auto'>
        <div className="w-full flex justify-between">
          <a className='flex-1 ' href='#/rules' title='Rules' onClick={(e) => { setShowRules(true); e.preventDefault(); }}>
            <QuestionMarkCircleIcon className='w-6 h-6' />
          </a>
          <div className='flex flex-1'>
            <div className='m-auto font-bold'>
              PnrPOKER
            </div>
          </div>
          <div className='flex flex-1 justify-end'>
            <a href='#/statistics' title='Statistics' onClick={(e) => { setShowStats(true); e.preventDefault(); }}>
              <ChartBarIcon className='w-6 h-6 mr-1' />
            </a>
            <a href='#/settings' title='Settings' onClick={(e) => { setShowOpts(true); e.preventDefault(); }}>
              <CogIcon className='w-6 h-6' />
            </a>
          </div>
        </div>
      </div>
    </nav>

    <InitInfo info={serverResponse.info} hardMode={hardMode} expertMode={expertMode} />


    {!hardMode && !expertMode ?
      <div className="flex h-full text-white">
        <div className="m-auto">
        </div>
      </div>
      : null}

    <PlayField hands={hands} />

    {!hardMode && !expertMode ?
      <div className="flex h-full text-white">
        <div className="m-auto flex flex-col text-center">
          <div>
            L/M/H RANGES: <strong className='ml-2'>L</strong>=A-4   <strong className='ml-2'>M</strong>=5-9   <strong className='ml-2'>H</strong>=10-K
          </div>

          <div>
            CURRENT SUM OF GUESS HAND #{handIndex < NUM_GUESSES ? handIndex + 1 : NUM_GUESSES}: <strong>{handSum}</strong>
          </div>
        </div>
      </div> : null}

    <Keyboard canDone={cardIndex === 5} canBackspace={cardIndex > 0} onEnter={enterCard} onDone={handDone} onBackspace={backspaceCard} />

    <div className={"fixed top-[50%] flex w-full transition-opacity duration-300 " + (showDuplicateWarning ? 'opacity-100' : 'opacity-0')}>
      <div className="m-auto">
        <div className="rounded bg-white text-red-700 text-center font-bold shadow p-3">
          ERROR - CANNOT ENTER SAME CARD<br />
          TWICE IN A GUESS HAND
        </div>
      </div>
    </div>

  </div> : <div className='flex w-full flex-col h-screen dark:bg-[#35654d] dark:text-white' >
    <div className='m-auto text-xl flex'> <RefreshIcon className='w-6 h-6 animate-spin' /> Please wait...</div>
  </div>
  )}</div>;
}

export default App;
