enum CardSuit {
  NONE = "",
  CLUB = "♣",
  DIAMOND = "♦",
  HEART = "♥",
  SPADE = "♠",
}

enum CardValue {
  NONE = "",
  CA = "A",
  C2 = "2",
  C3 = "3",
  C4 = "4",
  C5 = "5",
  C6 = "6",
  C7 = "7",
  C8 = "8",
  C9 = "9",
  T = "10",
  J = "J",
  Q = "Q",
  K = "K",
}

const CardNumericValue: { [key: string]: number } = {
  A: 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
  "6": 6,
  "7": 7,
  "8": 8,
  "9": 9,
  "10": 10,
  J: 11,
  Q: 12,
  K: 13,
};

const STRING_VALS: { [key: string]: CardValue } = {
  A: CardValue.CA,
  "2": CardValue.C2,
  "3": CardValue.C3,
  "4": CardValue.C4,
  "5": CardValue.C5,
  "6": CardValue.C6,
  "7": CardValue.C7,
  "8": CardValue.C8,
  "9": CardValue.C9,
  T: CardValue.T,
  J: CardValue.J,
  Q: CardValue.Q,
  K: CardValue.K,
};

const STRING_SUITS: { [key: string]: CardSuit } = {
  h: CardSuit.HEART,
  d: CardSuit.DIAMOND,
  s: CardSuit.SPADE,
  c: CardSuit.CLUB,
};

function uSuit(uni: string): CardSuit {
  switch (uni) {
    case "♠":
      return CardSuit.SPADE;
    case "♣":
      return CardSuit.CLUB;
    case "♥":
      return CardSuit.HEART;
    case "♦":
      return CardSuit.DIAMOND;
  }
  return CardSuit.NONE;
}

function suitName(suit: CardSuit) {
  switch (suit) {
    case CardSuit.CLUB:
      return "Club";
    case CardSuit.DIAMOND:
      return "Diamond";
    case CardSuit.HEART:
      return "Heart";
    case CardSuit.SPADE:
      return "Spade";
  }
}

function valueName(value: CardValue) {
  switch (value) {
    case CardValue.CA:
      return "Ace";
    case CardValue.T:
      return "10";
    case CardValue.J:
      return "Jack";
    case CardValue.Q:
      return "Queen";
    case CardValue.K:
      return "King";
  }

  return value;
}

function suitStyle(suit: CardSuit, success?: boolean) {
  return suit === CardSuit.CLUB || suit === CardSuit.SPADE || suit === undefined
    ? "text-black"
    : success
    ? "text-red-800"
    : "text-red-600";
}

function validCard(cardData: CardData) {
  return cardData.suit !== CardSuit.NONE && cardData.value !== CardValue.NONE;
}

function eqCards(a: CardData | null, b: CardData | null) {
  return a?.suit === b?.suit && a?.value === b?.value;
}

function emptyCard(): CardData {
  return { suit: CardSuit.NONE, value: CardValue.NONE };
}

function isEmptyCard(card: CardData): boolean {
  return card.suit === CardSuit.NONE && card.value === CardValue.NONE;
}

function dataFromStr(
  str: string,
  success?: boolean,
  inPos?: boolean
): CardData {
  const vs = str[0];
  const ss = str[1];

  return {
    suit: STRING_SUITS[ss],
    value: STRING_VALS[vs],
    success,
    inPos,
  };
}

function handFromStr(str: string): CardData[] {
  return str.split(",").map((s) => dataFromStr(s.trim()));
}

function hasCard(arr: CardData[], card: CardData) {
  for (const acard of arr) if (eqCards(acard, card)) return true;
  return false;
}

function cloneCard(card: CardData): CardData {
  return {
    suit: card.suit,
    value: card.value,
  };
}

export interface CardData {
  suit: CardSuit;
  value: CardValue;
  inPos?: boolean;
  success?: boolean;
}

export interface HandData {
  guess: CardData[];
  result: CardData[];
}

export {
  CardNumericValue,
  CardSuit,
  CardValue,
  cloneCard,
  dataFromStr,
  emptyCard,
  eqCards,
  handFromStr,
  hasCard,
  isEmptyCard,
  suitName,
  suitStyle,
  uSuit,
  validCard,
  valueName,
};
