import { XIcon } from "@heroicons/react/outline";
import { ReactNode } from "react";

export default function Popup(props: { children?: ReactNode, show: boolean, title?: string, onClose: () => void }) {
    return <div className={"fixed left-0 top-0 right-0 bottom-0 z-10 transition-all " + (props.show ? 'visible bg-slate-900/80' : 'invisible bg-none')}>
        <div className={"flex flex-col m-3 mob-width shadow bg-black text-white dark:bg-poker-900 dark:text-white h-full transition-transform " + (props.show ? 'scale-100' : 'scale-0')}>
            <div className="flex justify-end p-3">
                <div className="font-bold text-xl grow">
                    {props.title}
                </div>
                <a href="#/close" onClick={(e) => { props.onClose(); e.preventDefault(); }}>
                    <XIcon className="w-7 h-7" />
                </a>
            </div>

            <div className="p-3 h-full overflow-auto">
                {props.children}
            </div>

        </div>
    </div>;
}