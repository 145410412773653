import { BackspaceIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { CardSuit, CardValue, suitName, validCard, valueName } from "./CardData";
import { KeyboardKey } from "./KeyboardKey";

interface KeyboardProps {
    canBackspace: boolean,
    canDone: boolean,
    onDone: () => void;
    onEnter: (suit: CardSuit, value: CardValue) => void;
    onBackspace: () => void;
}

export function DoneButton(props: { onDone: () => void }) {
    return <div className="kbd-key w-14 md:w-auto bg-blue-500 text-white" onClick={props.onDone}>
        <div className="m-auto pl-1 pr-1 font-bold">
            Guess
        </div>
    </div>
}

export function EnterButton(props: { enter: () => void; canEnter: boolean }) {
    return <div className="kbd-key w-14 md:w-auto bg-slate-200 text-black" onClick={props.enter}>
        <div className={"m-auto pl-1 pr-1 font-bold " + (!props.canEnter ? "text-slate-400" : "")}>
            Enter
        </div>
    </div>
}

export function BackspaceButton(props: { backspace: () => void; canBackspace: boolean }) {
    return <div className="kbd-key w-14 md:w-auto bg-slate-200 text-black" onClick={props.backspace}>
        <div className={"m-auto pl-1 pr-1 font-bold"}>
            <BackspaceIcon className={"w-5 h-5 " + (!props.canBackspace ? "stroke-slate-400" : "")} />
        </div>
    </div>
}

export function Keyboard(props: KeyboardProps) {
    const [cardValue, setCardValue] = useState(CardValue.NONE);
    const [cardSuit, setCardSuit] = useState(CardSuit.NONE);

    const values: CardValue[][] = [
        [CardValue.CA, CardValue.C2, CardValue.C3, CardValue.C4, CardValue.C5, CardValue.C6, CardValue.C7],
        [CardValue.C8, CardValue.C9, CardValue.T, CardValue.J, CardValue.Q, CardValue.K]
    ];

    const suits: CardSuit[] = [
        CardSuit.SPADE,
        CardSuit.HEART,
        CardSuit.CLUB,
        CardSuit.DIAMOND,
    ];

    const canEnter = !props.canDone && validCard({ suit: cardSuit, value: cardValue });

    function enter() {
        if (canEnter) {
            props.onEnter(cardSuit, cardValue)

            setCardSuit(CardSuit.NONE);
            setCardValue(CardValue.NONE);
        }
    }

    function backspace() {
        if (props.canBackspace)
            props.onBackspace();
    }

    return <div className="mob-width flex-none md:pb-1">
        {values.map(function (row, index) {
            return <div key={`row-${index}`} className='kbd-row'>
                {row.map(function (val) {
                    return <KeyboardKey key={val} title={valueName(val)} selected={val === cardValue} value={val} select={() => setCardValue(val)} />
                })}
            </div>
        })}

        <div className='kbd-row'>
            <BackspaceButton backspace={backspace} canBackspace={props.canBackspace} />

            {suits.map(function (suit) {
                return <KeyboardKey title={suitName(suit)} key={suit} suit={suit} selected={suit === cardSuit} select={() => setCardSuit(suit)} />;
            })}

            {props.canDone ?
                <DoneButton onDone={props.onDone} />
                :
                <EnterButton enter={enter} canEnter={canEnter} />
            }
        </div>
    </div>;
}