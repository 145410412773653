
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { detect } from 'detect-browser';

dayjs.extend(timezone);

const browser = detect();

export default function EmailLink() {

    const deviceSummary: string[] = ['', '--', 'Device summary:'];

    function capitalize(str: string) {
        return str[0].toUpperCase() + str.slice(1);
    }

    deviceSummary.push('Page: ' + window.location.href);
    deviceSummary.push('Platform: ' + browser?.os);
    deviceSummary.push('Browser: ' + capitalize(browser!.name) + ' v' + browser?.version);
    deviceSummary.push('Screen Resolution: ' + window.screen.availWidth + 'x' + window.screen.availHeight);
    deviceSummary.push('Timezone: ' + dayjs.tz.guess() + ` (GMT${dayjs(Date.now()).format('Z')})`);

    return <a href={"mailto:feedback@pnrpoker.com?subject=" + encodeURIComponent('PnrPOKER Feedback') + '&body=' + encodeURIComponent(deviceSummary.join('\n'))} className="underline">Email</a>
}