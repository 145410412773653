import { CardSuit, suitStyle } from "./CardData";
import HandInfo from "./HandInfo";

function displayLHM(lhm: string, hardMode: boolean, expertMode: boolean) {
    if (hardMode) {
        return lhm.split('').map((c, i) => (i + 1) % 2 === 0 ? '?' : c);
    }

    if (expertMode) {
        return '?????';
    }
    return lhm;
}

export function Count(props: { suit: CardSuit, count: number }) {
    return <span className='mr-0 pr-1'><span className={suitStyle(props.suit)}>{props.suit}</span>:<span className="font-bold">{props.count}</span></span >
}

export function SuitCounts(props: { hardMode: boolean; sum: number; count: { s: number; h: number; d: number; c: number }, showSum: boolean; hideSuits?: boolean }) {
    return <>
        {props.showSum ? <>SUM: <span className="font-bold">{props.sum}</span> &nbsp; </> : null}{props.hideSuits !== true ? <>SUITS: </> : null}<span className=""><Count suit={CardSuit.SPADE} count={props.count.s} />
            <Count suit={CardSuit.HEART} count={props.count.h} />
            <Count suit={CardSuit.CLUB} count={props.count.c} />
            <Count suit={CardSuit.DIAMOND} count={props.count.d} />
        </span>
    </>
}

export default function InitInfo(props: { info: HandInfo, hardMode: boolean; expertMode: boolean; }) {
    const info = props.info;

    return <div className="mob-width flex flex-none flex-col w-full overflow-hidden bg-white md:rounded md:mt-2 md:mb-2">
        <div className="text-center md:text-xl rounded bg-yellow-0 md:p-1 p-0 m-auto leading-[1rem]">
            <div>
                RANK: <span className="font-bold">{info.rank.toUpperCase()} </span> &nbsp; L/M/H: <span className="font-bold">{info.lmh ? displayLHM(info.lmh, props.hardMode, props.expertMode) : null}</span>
            </div>
            <div>
                <SuitCounts hardMode={props.hardMode} sum={info.sum} count={info.count} showSum />
            </div>
        </div>
    </div>
}