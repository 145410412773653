import { NUM_GUESSES } from './App';
import GameHistoryItem from "./GameHistoryItem";


function StatItem(props: { value: string, label: string }) {
    return <div className="flex flex-col text-center w-1/4">
        <div className="text-3xl">{props.value}</div>
        <div className="text-xs">{props.label}</div>
    </div>
}


export default function Statistics(props: { history: GameHistoryItem[] }) {
    const played = props.history.length;
    const wonGames = props.history.filter(g => g.win);
    const wonNum = wonGames.length;

    const dist: { [key: number]: number } = {};

    for (let n = 1; n <= NUM_GUESSES; n++) {
        dist[n] = wonGames.filter(g => g.guessNum === n).length;
    }

    const streaks: GameHistoryItem[][] = [];

    let streak: GameHistoryItem[] = [];

    for (const item of props.history) {
        if (item.win) {
            streak.push(item);
        } else {
            if (streak.length > 0) {
                streaks.push(streak);
                streak = [];
            }
            streaks.push([]);
        }
    }

    streaks.push(streak);

    let maxStreak = 0;

    for (const str of streaks) {
        if (str.length > maxStreak)
            maxStreak = str.length;
    }

    const currentStreak = streaks.length > 0 ? streaks[streaks.length - 1].length : 0;

    const range: number[] = [];

    for (let n = 1; n <= NUM_GUESSES; n++)
        range.push(n);

    return <div className="flex flex-col">
        <div className="flex justify-center">
            <StatItem value={`${played}`} label="Played" />
            <StatItem value={`${played > 0 ? Math.floor(wonNum / played * 100) : 0}`} label="Win %" />
            <StatItem value={`${currentStreak}`} label="Current Streak" />
            <StatItem value={`${maxStreak}`} label="Max Streak" />
        </div>

        <div className="font-bold text-center mt-5 uppercase"># of guesses distribution</div>

        <div className="flex flex-col">
            {range.map(function (num, index) {
                return <div className="flex mb-1" key={index}>
                    <div className="w-5">{num}</div>
                    {dist[num] > 0 ? <div className="w-full">
                        <div className="flex bg-slate-800 text-white dark:text-poker-900 dark:bg-white pr-2 justify-end align-middle font-bold" style={{ width: `${dist[num] / played * 100}%` }}>
                            <div className="flex h-full">
                                <span className="m-auto">
                                    {dist[num]}
                                </span>
                            </div>
                        </div>
                    </div> : null}
                </div>
            })}
        </div>

    </div>
}