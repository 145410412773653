import { HandData } from "./CardData";
import Hand from "./Hand";

interface CardRowProps {
    hand: HandData
}

export default function CardsRow(props: CardRowProps) {
    return <div className="flex justify-around md:mb-1">
        <Hand cards={props.hand.guess} />
        <Hand cards={props.hand.result} result />
    </div>;
}