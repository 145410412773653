import { ShareIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { LS_PFIX, NUM_GUESSES } from "./App";
import { CardSuit, CardValue, HandData } from "./CardData";

export default function Share(props: { guessNum: number, hands: HandData[], level: string }) {
    const [copiedMessage, setCopiedMessage] = useState(false);
    const [inProgress, setInProgress] = useState(false);

    function onClick() {

        const res: string[] = [];

        for (const hand of props.hands) {
            let cres = '';

            if (hand.result.length > 0) {
                for (const card of hand.result) {
                    if (card.suit !== CardSuit.NONE || card.value !== CardValue.NONE) {
                        if (card.success || card.inPos === true) {
                            cres += '🟩';
                        } else if (!card.inPos) {
                            cres += '🟨';
                        } else {
                            cres += '⬜';
                        }
                    } else {
                        cres += '🟦';
                    }
                }
            } else {
                cres = '🟦🟦🟦🟦🟦'
            }

            res.push(cres);
        }

        const today = localStorage.getItem(LS_PFIX + '_currentDate');

        const shareMessage = `PnrPOKER\n${today} ${props.guessNum}/${NUM_GUESSES}\nLevel: ${props.level}\n\n${res.join('\n')}`;

        if (window.navigator.share) {
            setInProgress(true);
            window.navigator.share({ title: 'PnrPOKER', text: shareMessage }).then(() => {
                setInProgress(false);
            }).catch((reason) => {
                setInProgress(false);
            });
        } else {
            setInProgress(true);
            navigator.clipboard.writeText(shareMessage).then(() => {
                setInProgress(false);
                setCopiedMessage(true);

                setTimeout(() => {
                    setCopiedMessage(false);
                }, 3000);

            }).catch(() => {
                setInProgress(false);
            });

        }
    }

    return <div className="flex p-3">
        <button className="text-white dark:text-black rounded p-2 bg-blue-700 dark:bg-white font-bold flex shadow m-auto" onClick={onClick} disabled={inProgress}>
            <span className="m-auto flex">
                <span>Share</span> <ShareIcon className="w-4 h-4 m-auto ml-1" />
            </span>
        </button>

        <div className={"fixed bottom-10 left-0 w-full transition-opacity " + (copiedMessage ? 'opacity-100' : 'opacity-0')}>
            <div className="flex">
                <div className="m-auto bg-black rounded-lg text-white font-bold p-2 pl-5 pr-5">
                    Results copied to Clipboard
                </div>
            </div>
        </div>
    </div>
}