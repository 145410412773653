import { CardSuit, CardValue, suitStyle } from "./CardData";

interface KeyboardKeyProps {
    suit?: CardSuit,
    value?: CardValue,
    selected?: boolean,
    title?: string,
    select?: () => void
}

export function KeyboardKey(props: KeyboardKeyProps) {
    return <div title={props.title} className={"kbd-key select-none " + (props.selected ? "bg-sky-500 text-white" : "bg-slate-50") + " pl-2 pr-2 md:w-[30px] md:h-[35px] md:p-0"} onClick={() => { if (props.select) props.select(); }}>
        <div className={"m-auto text-xl font-bold p-[2px] " + (props.suit ? suitStyle(props.suit!) : '')}>
            {props.suit ?
                <>{props.suit}</>
                : null}

            {props.value ?
                <>{props.value}</> : null}
        </div>
    </div>;
}