import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import { NUM_GUESSES } from "./App";
import Card from "./Card";
import { CardSuit, CardValue, handFromStr } from "./CardData";
import Hand from "./Hand";
import { SuitCounts } from "./InitInfo";

export default function Quickstart() {
    return <>
        <p>Welcome to the exciting game of PnrPOKER!</p>

        <div className="mb-4">(For help, click on <QuestionMarkCircleIcon className="w-5 h-5 inline-block" />)</div>

        <h2>Objective</h2>

        <div className="mb-2">Match the daily five-card poker hand by guessing the correct cards (value, suit, position) within {NUM_GUESSES} guesses.  Remember that card position matters, so a full house guess hand of:
            <div className="w-full"><Hand cards={handFromStr('Ks,Kd,Kc,2d,2c')} /></div>
            would NOT count as a winning match of the full house daily poker hand of:
            <div className="w-full"><Hand cards={handFromStr('Ks,Kd,2d,Kc,2c')} /></div>
        </div>

        <div className="mb-2">
            Share your results with your friends and challenge them to beat your score!  (daily poker hands change at 12:01am GMT)
        </div>

        <h2>Initial Information</h2>

        <p>You get four pieces of initial information to help you make guesses.</p>

        <div className="mb-1">Example Daily Poker Hand:<Hand cards={handFromStr('8s,3s,8h,Jd,3d')} /> </div>


        <ul className="list-decimal list-outside pl-4 mb-2">
            <li className="mb-2">
                <div><u>Rank</u>: standard poker hand rank of daily poker hand</div>
                <div>Example Rank: TWO PAIR</div>
            </li>
            <li className="mb-2">
                <div>
                    <u>Low/Middle/High (L/M/H) Value</u>: L/M/H value of each card (left to right) in daily poker hand, where Low=A-4, Middle=5-9, High=10-K
                </div>
                <div className="">
                    Example L/M/H Value: MLMHL
                </div>
            </li>
            <li className="mb-2">
                <div><u>Sum</u>: total sum of daily poker hand (A=1; J=11; Q=12; K=13)</div>
                <div>Example Sum: 33</div>
            </li>
            <li className="mb-2">
                <u>Suits</u>: # of each suit in daily poker hand
                <div>
                    Example Suits: <SuitCounts sum={0} showSum={false} count={{
                        s: 2, h: 1, c: 0, d: 2
                    }} hardMode={false} hideSuits />
                </div>
            </li>
        </ul>


        <h2>Guess-Based Information</h2>

        <div>After you enter your guess, you get additional information (5 cards to the right of your guess) to help you make your next guess:</div>

        <div>
            Green cards: <div className="inline-block w-[35px] h-[50px] mr-2"><Card card={{ suit: CardSuit.HEART, value: CardValue.CA, success: true }} index={0} /></div><div className="inline-block w-[35px] h-[50px] mr-2"><Card card={{ suit: CardSuit.NONE, value: CardValue.CA, success: true }} index={0} /></div><div className="inline-block w-[35px] h-[50px] mr-2"><Card card={{ suit: CardSuit.HEART, value: CardValue.NONE, success: true }} index={0} /></div>
            <p>(Value and/or suit guess inside is in the correct position)</p>
        </div>

        <div className="mb-2">
            Yellow cards: <div className="inline-block w-[35px] h-[50px] mr-2"><Card card={{ suit: CardSuit.HEART, value: CardValue.CA, success: false, inPos: false }} index={0} /></div><div className="inline-block w-[35px] h-[50px] mr-2"><Card card={{ suit: CardSuit.NONE, value: CardValue.CA, success: false, inPos: false }} index={0} /></div><div className="inline-block w-[35px] h-[50px] mr-2"><Card card={{ suit: CardSuit.HEART, value: CardValue.NONE, success: false, inPos: false }} index={0} /></div>
            <p>(Value and/or suit guess inside is in the incorrect position)</p>
        </div>

        <div className="mb-2">
            Note that, if "High Contrast Mode" is on, then players will see white cards instead of green cards.
        </div>

        <div>
            <div>Green/Yellow cards are subject to the following rules: </div>
            <div>(a) Highest Priority; and </div>
            <div>(b) No Double-Counting</div>
            (Click <QuestionMarkCircleIcon className='w-5 h-5 inline-block' /> for a detailed explanation of these rules)
        </div>
    </>
}