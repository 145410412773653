import { useState } from "react";
import EmailLink from "./EmailLink";
import Toggle from "./Toggle";
import TwitterLink from "./TwitterLink";

export default function Settings(props: { darkMode: boolean, hardMode: boolean, expertMode: boolean, changeDarkMode: (val: boolean) => void, changeHardMode: (val: boolean) => void, changeExpertMode: (val: boolean) => void }) {

    const [highContrastMode, setHighContrastMode] = useState(!props.darkMode);
    const [resetRequest, setResetRequest] = useState(false);

    function changeHighContrastMode(val: boolean) {
        setHighContrastMode(val);
        props.changeDarkMode(!val);
    }

    function onReset() {
        localStorage.clear();
        window.location.reload();
    }

    return <div className="flex flex-col h-full w-full justify-between">
        <div className="flex flex-col">

            <div className="flex text-lg justify-between mb-4">
                <div>
                    High Contrast Mode:
                </div>
                <Toggle value={highContrastMode} onChange={() => changeHighContrastMode(!highContrastMode)}></Toggle>
            </div>
            <div className="flex text-lg justify-between mb-4">
                <div>
                    Hard Mode:
                </div>
                <Toggle value={props.hardMode} onChange={() => props.changeHardMode(!props.hardMode)}></Toggle>
            </div>
            <div className="flex text-lg justify-between mb-4">
                <div>
                    Expert Mode:
                </div>
                <Toggle value={props.expertMode} onChange={() => props.changeExpertMode(!props.expertMode)}></Toggle>
            </div>
            <div className="flex text-lg justify-between mb-4">
                <div>
                    Feedback:
                </div>

                <EmailLink />
            </div>
            <div className="flex text-lg justify-between mb-4">
                <div>
                    Community:
                </div>

                <TwitterLink />
            </div>
        </div>

        <div className="w-full">
            {resetRequest ? <div className="p-2 bg-white text-black dark:bg-poker-100 dark:text-black rounded shadow text-center">
                <div className="font-bold text-lg">Are you sure?</div>
                <div>Resetting will <strong>delete all of your past game statistics</strong>.</div>
                <div>Click <strong>YES</strong> to confirm reset or <strong>NO</strong> to exit without reset</div>
                <div className="flex justify-around text-white mt-2">
                    <button className="bg-red-800 p-3 pl-4 pr-4 rounded shadow" onClick={onReset}>YES</button>
                    <button className="bg-slate-600 p-3 pl-4 pr-4 rounded shadow" onClick={() => setResetRequest(false)}>NO</button>
                </div>
            </div> :
                <>
                    <div className="font-bold mb-1">
                        Click below only if game malfunctioning:
                    </div>
                    <button className="rounded p-2 bg-red-800 text-white w-full" onClick={() => setResetRequest(true)}>Reset</button>
                </>
            }
        </div>
    </div>
}