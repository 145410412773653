import { CogIcon } from '@heroicons/react/solid';
import { NUM_GUESSES } from './App';
import Card from './Card';
import { CardData, CardSuit, CardValue, dataFromStr, emptyCard, handFromStr } from './CardData';
import EmailLink from './EmailLink';
import Hand from './Hand';
import { SuitCounts } from './InitInfo';
import { BackspaceButton, DoneButton, EnterButton } from './Keyboard';
import './Rules.css';
import TwitterLink from './TwitterLink';

export function CardPic(props: { card: CardData }) {
    return <div className="inline-block w-[35px] h-[50px]"><Card card={props.card} index={0} /></div>
}

export default function Rules() {
    return <div>
        <div className='mb-4'>Welcome to the exciting game of PnrPOKER!</div>

        <div className="mb-4">(Troubleshooting information is located near the bottom of this document)</div>

        <h2>Objective</h2>

        <div className='mb-2'>Match the daily five-card poker hand by guessing the correct cards (value, suit, position) within {NUM_GUESSES} guesses.  Remember that card position matters, so a full house guess hand of:
            <div className="w-full"><Hand cards={handFromStr('Ks,Kd,Kc,2d,2c')} /></div>
            would NOT count as a winning match of the full house daily poker hand of:
            <div className="w-full"><Hand cards={handFromStr('Ks,Kd,2d,Kc,2c')} /></div>
        </div>

        <div className="mb-2">
            Share your results with your friends and challenge them to beat your score! (daily poker hands change at 12:01am GMT)
        </div>

        <h2>Guess Input</h2>

        <div className='mb-4'>Guess a card by choosing a value and suit on the keyboard, then clicking <div className="inline-block"><EnterButton canEnter enter={() => null} /></div>.  Click <div className="inline-block"><BackspaceButton canBackspace backspace={() => null} /></div> to remove any previously entered cards.  Click <div className="inline-block"><DoneButton onDone={() => null} /></div> to submit your guess after all five cards are entered.</div>

        <h2>Initial Information</h2>

        <div>You get four pieces of initial information to help you make guesses.</div>

        <div>Example Daily Poker Hand: <Hand cards={handFromStr('8s,3s,8h,Jd,3d')} /></div>

        <ul className='list-decimal list-outside pl-4'>
            <li className='mb-2'>
                <div><u>Rank</u>: standard poker hand rank of daily poker hand</div>

                <div>Example Rank: TWO PAIR</div>

                <div>
                    The two pair results because there are two 8's and two 3's.  For straights in standard poker hand ranks, remember that A's can be used as low card (A-2-3-4-5) or high card (A-K-Q-J-10).
                </div>
            </li>

            <li className='mb-2'>
                <div><u>Low/Middle/High (L/M/H) Value</u>: L/M/H value of each card (left to right) in daily poker hand, where Low=A-4, Middle=5-9, High=10-K</div>

                <div>Example L/M/H Value: MLMHL</div>

                <div>"M" in first left position results because the <CardPic card={dataFromStr('8s')} /> is in the first card position, etc.</div>
            </li>

            <li className='mb-2'>
                <div><u>Sum</u>: total sum of the daily poker hand (A=1; J=11; Q=12; K=13)</div>

                <div>Example Sum: 33</div>

                <div>The sum is calculated as 8+3+8+11+3=33.  The poker hand cards 2-10 simply have their 2 through 10 number values.  While A could be defined as either "1" or "14", easier to remember "A=1", where values to sum range from 1-13, instead of 2-14.</div>
            </li>

            <li className='mb-2'>
                <div><u>Suits</u>: # of each suit in daily poker hand</div>

                <div>Example Suits: <div className="inline-block bg-white text-black pl-2 pr-2">SUITS: <SuitCounts sum={0} showSum={false} count={{
                    s: 2, h: 1, c: 0, d: 2
                }} hardMode={false} hideSuits /></div></div>

                <div>The spades # result is "2" because there are two cards, <div className="inline-block w-[35px] h-[50px]"> <Card card={{ suit: CardSuit.SPADE, value: CardValue.C8 }} index={0} /></div> and <div className="inline-block w-[35px] h-[50px]"> <Card card={{ suit: CardSuit.SPADE, value: CardValue.C3 }} index={0} /></div>, that contain spades.</div>
            </li>
        </ul>

        <h2>Guess-Based Information</h2>

        <div>After you enter your guess, you get additional information (5 cards to the right of your guess) to help you make your next guess.</div>

        <div>Green cards (if "High Contrast Mode" is on, then players will see white cards instead) indicate that the value and/or suit guess inside is in the correct position.  Yellow cards indicate that the value and/or suit guess inside is in the incorrect position, but in the daily poker hand somewhere.</div>

        <ul className="list-outside list-decimal pl-4 mb-4">

            <li>Green Card (value & suit inside): <div className="inline-block w-[35px] h-[50px]"> <Card card={{ suit: CardSuit.HEART, value: CardValue.CA, success: true }} index={0} /></div>
                <div>= guess card: correct value, correct suit, correct position</div></li>

            <li>Yellow Card (value & suit inside): <div className="inline-block w-[35px] h-[50px]"> <Card card={{ suit: CardSuit.HEART, value: CardValue.CA, success: false, inPos: false }} index={0} /></div>
                <div>= guess card: correct value, correct suit, incorrect position</div></li>

            <li>Green Card (only value inside): <div className="inline-block w-[35px] h-[50px]"> <Card card={{ suit: CardSuit.NONE, value: CardValue.CA, success: false, inPos: true }} index={0} /></div>
                <div>= guess card: correct value, incorrect suit, correct position</div></li>

            <li>Green Card (only suit inside): <div className="inline-block w-[35px] h-[50px]"> <Card card={{ suit: CardSuit.HEART, value: CardValue.NONE, success: false, inPos: true }} index={0} /></div>
                <div>= guess card: incorrect value, correct suit, correct position</div></li>

            <li>Yellow Card (only value inside): <div className="inline-block w-[35px] h-[50px]"> <Card card={{ suit: CardSuit.NONE, value: CardValue.CA, success: false, inPos: false }} index={0} /></div>
                <div>= guess card: correct value, incorrect suit, incorrect position</div></li>

            <li>Yellow Card (only suit inside): <div className="inline-block w-[35px] h-[50px]"> <Card card={{ suit: CardSuit.HEART, value: CardValue.NONE, success: false, inPos: false }} index={0} /></div>
                <div>= guess card: incorrect value, correct suit, incorrect position</div></li>

            <li>Blue Card (nothing inside): <div className="inline-block w-[35px] h-[50px]"> <Card card={{ suit: CardSuit.NONE, value: CardValue.NONE, success: false, inPos: false }} index={0} /></div>
                <div>= guess card: incorrect value, incorrect suit, incorrect position</div></li>
        </ul>

        <div className="mb-2">
            Each guess card results in only <u>one</u> of the seven guess-based information cards being shown (in the same position) to the right of the guess.  Providing only one card to the player makes the game more challenging and less confusing.  Which one of the seven cards above is shown is based on two principles: (a) highest priority; and (b) no double-counting.  PnrPOKER generates guess-based information cards by first checking all daily poker hand cards, from left to right, to see if they meet priority #1, then priority #2, then priority #3, etc.
        </div>

        <div className="mb-2">
            <div className="underline">(a) Highest Priority</div>
            If a guess card meets the requirements of multiple guess-based information cards (priority #1-#7) above, only the highest priority guess-based information card will be shown.  Priority is given to the guess with the most variables (card value, suit, position) correct, with tiebreakers of card value correct first, then suit correct, then position correct.
        </div>

        <div className="">Example:</div>
        <div>Daily Poker Hand</div>
        <div className="w-full"><Hand cards={handFromStr('7d,3h,As,4c,7c')} /></div>
        <div>Guess Hand</div>
        <div className="w-full"><Hand cards={handFromStr('7d,2d,8s,9d,As')} /></div>
        <div>Guess-Based Information Hand</div>
        <div className="w-full"><Hand cards={[{ suit: CardSuit.DIAMOND, value: CardValue.C7, inPos: true }, emptyCard(), emptyCard(), emptyCard(), { suit: CardSuit.SPADE, value: CardValue.CA, inPos: false }]} /></div>

        <div className="mb-2">
            Note that the first guess-based information card shown is <div className="inline-block w-[35px] h-[50px] mr-2"><Card card={{ suit: CardSuit.DIAMOND, value: CardValue.C7, success: true }} index={0} /></div> (priority #1 – since first guess card <div className="inline-block w-[35px] h-[50px] mr-2"><Card card={{ suit: CardSuit.DIAMOND, value: CardValue.C7, success: false }} index={0} /></div> is correct value, correct suit, correct position match for first daily poker hand card <div className="inline-block w-[35px] h-[50px] mr-2"><Card card={{ suit: CardSuit.DIAMOND, value: CardValue.C7, success: false }} index={0} /></div>) rather than <div className="inline-block w-[35px] h-[50px] mr-2"><Card card={{ suit: CardSuit.NONE, value: CardValue.C7, inPos: false }} index={0} /></div> (priority #5 – since first guess card <div className="inline-block w-[35px] h-[50px] mr-2"><Card card={{ suit: CardSuit.DIAMOND, value: CardValue.C7, success: false }} index={0} /></div> is also correct value, incorrect suit, incorrect position match for fifth daily poker hand card <CardPic card={dataFromStr('7c')} />).
        </div>

        <div className="mb-2">
            <div className="underline">(b) No Double-Counting</div>
            To avoid double-counting of a daily poker hand's information, a daily poker hand card's value <u>or</u> suit <u>or</u> position information can only be used once during any particular guess.
        </div>

        <div className="">Example:</div>
        <div>Daily Poker Hand</div>
        <div className="w-full"><Hand cards={handFromStr('7d,3h,As,4c,7c')} /></div>
        <div>Guess Hand</div>
        <div className="w-full"><Hand cards={handFromStr('7d,2d,8s,9d,As')} /></div>
        <div>Guess-Based Information Hand</div>
        <div className="w-full mb-2"><Hand cards={[{ suit: CardSuit.DIAMOND, value: CardValue.C7, inPos: true }, emptyCard(), emptyCard(), emptyCard(), { suit: CardSuit.SPADE, value: CardValue.CA, inPos: false }]} /></div>

        <div className="mb-2">
            Note that (i) the second guess-based information card is <CardPic card={{ suit: CardSuit.NONE, value: CardValue.NONE }} />, since the diamond information in the first daily poker hand card has already been used by the priority #1 <CardPic card={dataFromStr('7d', true)} />, and is therefore not available for use by the second guess card to later generate the priority #6 <CardPic card={{ suit: CardSuit.DIAMOND, value: CardValue.NONE, inPos: false }} />; and (ii) the third guess-based information card is <CardPic card={{ suit: CardSuit.NONE, value: CardValue.NONE }} />, since the spade information in the third daily poker hand has already been used by the priority #2 <CardPic card={{ suit: CardSuit.SPADE, value: CardValue.CA, inPos: false }} />, and is therefore not available for use by the third guess card to later generate the priority #4 <CardPic card={{ suit: CardSuit.SPADE, value: CardValue.NONE, inPos: true, success: true }} />.
        </div>

        <div className="mb-2">
            Priority #2 (correct value, correct suit, incorrect position) is given a higher priority than priority #4 (incorrect value, correct suit, correct position) because the PnrPOKER team believes that it is more useful, and less confusing, for players to receive the information that their value/suit guess was correct and is somewhere in the daily poker hand, rather than the information that their suit/position guess was correct.
        </div>

        <h2>Hard/Expert Mode</h2>
        <div className='mb-4'>If you find the game too easy, then click <CogIcon className='w-5 h-5 inline-block' />, and choose “Hard Mode”  (L/M/H initial information removed for 2nd and 4th card positions, e.g., "M?M?H") or "Expert Mode" (L/M/H initial information removed for all card positions, i.e., "?????").</div>

        <h2>Troubleshooting</h2>

        <ul className='list-disc list-outside pl-4 mb-2'>
            <li>If your screen does not load the game correctly or freezes, try refreshing the PnrPOKER website on your device.  For example, "downswiping" on your device screen usually refreshes websites on iOS/Android devices.  Refreshing the website will also reload the "Share" screen if you accidentally exit that screen before sharing your score with friends after completing a game.</li>
            <li>If your screen does not show the initial information or keyboard correctly, and you are in "Landscape Orientation", try "Portrait Orientation" on your device.</li>
            <li>If your device does not show the entire game screen without scrolling, try "unzooming" the view on your device's browser.  For example, on most desktops/laptops, setting your zoom to 80% (or lower, if necessary) will ensure that you can view the entire game screen without scrolling.</li>
            <li>If you are having trouble distinguishing the colors on the game screen because of colorblindness or other reasons, try clicking <CogIcon className='w-5 h-5 inline-block' /> and choose "High Contrast Mode" (replaces the dark green background with a black background, and replaces the green card boxes with white card boxes).</li>
            <li>If your screen does not show the guess cards correctly, consider updating your device's operating system.  For example, some users have reported guess card viewing problems when using iOS 14.4, but not after updating to iOS 15.3 (and later versions).</li>
            <li>If you are experiencing other errors, try clicking <CogIcon className='w-5 h-5 inline-block' /> and then click <div className="inline-block p-1 bg-red-800 rounded">Reset</div>.  Note that this will delete all of your past game statistics.</li>
        </ul>

        <h2>Feedback</h2>

        <div className="mb-2">
            PnrPOKER welcomes your feedback: <EmailLink />
        </div>

        <h2>Community</h2>

        <div className="mb-2">
            Join the growing PnrPOKER community: <TwitterLink />
        </div>

    </div>
}