import Card from "./Card";
import { CardData, CardSuit, CardValue } from "./CardData";

export default function Hand(props: { cards: CardData[], result?: boolean }) {
    const cardDatas: CardData[] = [];

    let allSuccess: boolean = true;

    for (let i = 0; i < 5; i++) {
        cardDatas[i] = props.cards[i] || { suit: CardSuit.NONE, value: CardValue.NONE };
        allSuccess = allSuccess && (cardDatas[i].success === true);
    }

    return <div className="flex w-5/12">
        {cardDatas.map(function (data, index) {
            return <div className="md:aspect-[3/4] w-1/5 aspect-[5/6] md:pr-1" key={index} >
                <Card index={index} card={data} result={props.result} allSuccess={props.result && allSuccess} />
            </div>
        })}
    </div>
}