import { useEffect, useState } from "react";
import { CardData, CardSuit, CardValue, suitStyle } from "./CardData";

interface CardProps {
    card: CardData;
    flipped?: boolean;
    index: number;
    result?: boolean;
    allSuccess?: boolean
}

function CardBack(props: { flipped?: boolean, index: number, result: boolean }) {
    return <div className={"rounded shadow shadow-slate-600 h-full w-full transition-transform duration-300 " + (props.flipped ? 'card-flipped' : 'card-faced') + (props.result ? ' delay-' + (props.index * 100) : '')}>
        <div className="bg-blue-500 border-white-600 border-4 w-full h-full rounded"></div>
    </div>;
}

function CardFace(props: { flipped?: boolean, card: CardData, index: number, result: boolean, jump?: boolean }) {
    let textColor = 'text-black';
    let bgColor = 'bg-white';

    if (props.card.suit !== CardSuit.NONE)
        textColor = suitStyle(props.card.suit, props.card.success);

    if (!(props.card.inPos == null || props.card.inPos))
        bgColor = 'bg-yellow-200';

    if (props.card.success || props.card.inPos === true)
        bgColor = 'dark:bg-green-400 bg-white';

    return <div className={"rounded absolute top-0 shadow shadow-slate-600 h-full w-full transition-all duration-300 " + textColor + ' ' + bgColor + (props.flipped ? ' invisible card-flipped' : ' visible card-faced') + (props.result ? ' delay-' + (props.index * 100) : '') + (props.jump ? ' animate-bounce' : '')}>
        <div className="flex flex-col justify-center h-full content-center select-none pb-2 pt-2">
            {props.card.value ?
                <div className={(props.card.suit !== CardSuit.NONE ? "text-left pl-1 " : "m-auto text-xl ") + ' text-sm md:text-xl'}>
                    {props.card.value}
                </div> : null}

            {props.card.suit ?
                <div className={(props.card.value !== CardValue.NONE ? "text-right pr-1 " : "m-auto text-xl ") + ' text-sm md:text-xl'}>
                    {props.card.suit}
                </div> : null}
        </div>
    </div>
}

export default function Card(props: CardProps) {
    const showCard = props.card.suit !== CardSuit.NONE || props.card.value !== CardValue.NONE;

    const [jump, setJump] = useState(false);

    useEffect(() => {
        if (props.allSuccess) {
            setTimeout(() => {
                setJump(true);
            }, 1000 + props.index * 100);
        }
    });

    return <div className={"relative w-full h-full transition-all" + (props.card.success ? ' scale-105' : '')}>
        <div className="absolute w-full h-full">
            <CardBack flipped={showCard} result={props.result === true} index={props.index} />
            <CardFace card={props.card} flipped={!showCard} result={props.result === true} index={props.index} jump={jump} />
        </div>
    </div>
}